.navbar {
	&.navbar-sticky {
		top: $app-header-height + $app-content-padding-y;
		position: sticky;
		padding: 0;

		@if $enable-rtl {
			border-right: 1px solid $border-color;
			margin-right: $spacer;
		} @else {
			border-left: 1px solid $border-color;
			margin-left: $spacer;
		}
		
		& .nav {
			width: 100%;
			flex-direction: column;
			
			& .nav-link {
				color: rgba($white, .5);
				padding: $spacer * .25 $spacer * 1.5;
				
				&:hover {
					color: rgba($white, .75);
				}
				&.active {
					color: var(--bs-theme);
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}