/*!
 * HUD v1.4.0 (https://seantheme.com/hud/)
 * Copyright 2022 SeanTheme
 */

@import "_functions.scss";
@import "_variables.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "_mixins.scss";

@import "_helper.scss";
@import "_reboot.scss";

@import "_app.scss";
@import "_layout.scss";
@import "_ui.scss";
@import "_plugins.scss";
@import "_pages.scss";
@import "_widgets.scss";
@import "_rtl.scss";

.pointer {
    cursor: pointer;
}

@for $i from 1 through 35 {
    .mt-#{$i} {
        margin-top: #{$i}px;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }

    .mr-#{$i} {
        margin-right: #{$i}px;
    }

    .ml-#{$i} {
        margin-left: #{$i}px;
    }

    .lh-#{$i} {
        line-height: #{$i}px;
    }
}

td[role="cell"] {
    vertical-align: middle;
}

.btn-ingroup {
    border-left: none;
}

.align-items-left {
    align-items: flex-start;
}
.align-items-right {
    align-items: flex-end;
}

.notify-icon {
    float: left;
    height: 36px;
    width: 36px;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    color: $app-header-bg !important;
}

.bg-warning {
    background-color: $warning;
}

.bg-danger {
    background-color: $danger;
}

.bg-success {
    background-color: $success;
}

.bg-info {
    background-color: $info;
}

.dropdown-item-notifications {
    display: block;
    width: 100%; // For `<button>`s
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit; // For `<button>`s
    text-decoration: if($link-decoration == none, null, none);
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s
}
