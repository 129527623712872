@mixin helper-color($color, $value) {
  .bg-gradient-#{$color} {
		background-color: $value !important;
		background-image: -moz-linear-gradient(top, lighten($value, 10%), darken($value, 5%)) !important;
		background-image: -webkit-linear-gradient(top, lighten($value, 10%), darken($value, 5%)) !important;
		background-image: linear-gradient(to bottom, lighten($value, 10%), darken($value, 5%)) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='lighten($value, 10%)', endColorstr='darken($value, 5%)',GradientType=0 ) !important;
  }
  .border-#{$color} {
  	@if ($color == 'theme') {
  		border-color: var(--bs-theme) !important;
  	}
  }
  :root .theme-#{$color} {
  	--bs-theme: #{$value} !important;
  	--bs-theme-rgb: #{to-rgb($value)} !important;
  	--bs-border-color: #{$value} !important;
  }
  @for $i from 1 through 4 {
  	.text-#{$color}-#{$i * 100} {
  		--bs-text-opacity: 1;
  		color: rgba(tint-color($value, (100 - ($i * 20))), var(--bs-text-opacity)) !important;
  	}
  	.bg-#{$color}-#{$i * 100} {
  		--bs-bg-opacity: 1;
  		background-color: rgba(tint-color($value, (100 - ($i * 20))), var(--bs-bg-opacity)) !important;
  	}
  	.text-#{$color}-#{($i + 5) * 100} {
  		--bs-text-opacity: 1;
  		color: rgba(shade-color($value, ($i * 20)), var(--bs-text-opacity))  !important;
  	}
  	.bg-#{$color}-#{($i + 5) * 100} {
  		--bs-bg-opacity: 1;
  		background-color: rgba(shade-color($value, ($i * 20)), var(--bs-bg-opacity)) !important;
  	}
  }
}