.note-editor {
	background: none;
	font-family: $font-family-base !important;
	
	&.note-frame,
	&.note-airframe {
		color: $body-color;
		
		& .note-toolbar {
			padding: ($card-spacer-y - rem(8px)) $card-spacer-x ($card-spacer-y - rem(4px));
			background: none;
			border-color: $border-color;
			border-radius: 0;
			
			& .note-btn-group {
				& > .note-btn {
					line-height: $line-height-base;
					min-height: rem(35px);
					background: none;
				
					@include button-outline-variant($default, $default);
					
					&:hover,
					&:focus {
						color: $black;
					}
					& .note-icon-caret {
						&:before {
							content: '';
						}
					}
					& > .note-icon-caret {
						&:first-child {
							@if $enable-rtl {
								margin-right: rem(-4px);
							} @else {
								margin-left: rem(-4px);
							}
						}
					}
				}
			}
			& .note-dropdown-menu {
				border: 0;
				padding: $dropdown-padding-y $dropdown-padding-x;
				background: $dropdown-bg;
				box-shadow: $dropdown-box-shadow;
				border-radius: $border-radius;
				
				& .note-dropdown-item {
					padding: $dropdown-item-padding-y $dropdown-item-padding-x;
					color: $dropdown-link-color;
					
					&:hover {
						background: $dropdown-link-hover-bg;
						color: $dropdown-link-hover-color;
					}
				}
			}
			& .note-palette {
				& .note-color-palette {
					& .note-color-row {
						display: flex;
						
						& .note-color-btn {
							display: block;
							min-height: initial;
						}
					}
				}
			}
			& .note-para {
				& .note-dropdown-menu {
					min-width: rem(235px);
				}
			}
		}
		& .note-editing-area {
			& .note-editable {
				padding: $card-spacer-y $card-spacer-x !important;
			}
		}
		&.fullscreen {
			top: $app-header-height !important;
			background: $dropdown-bg;
			
			@include media-breakpoint-up(md) {
				left: $app-sidebar-width !important;
			}
		}
		&.codeview {
			& .note-editing-area {
				& .note-codable {
					background: none;
				}
			}
		}
		& .note-statusbar .note-resizebar .note-icon-bar {
			border-color: rgba($white, .25) !important; 
		}
	}
	& .note-tooltip {
		& .note-tooltip-content {
			border-radius: $border-radius-sm;
		}
	}
	& .note-modal {
		padding: $app-header-height 0;
		overflow: scroll;
		
		& .note-modal-content {
			border: 0;
			background: $modal-backdrop-bg;
			border-radius: $modal-content-border-radius;
			box-shadow: none;
			
			& .note-modal-header {
				border: 0;
				padding: $modal-inner-padding;
				border-bottom: 1px solid $modal-header-border-color;
				
				& .note-modal-title {
					color: $headings-color;
					font-weight: $headings-font-weight;
					font-size: $h5-font-size;
				}
				& .close {
					color: $headings-color;
				}
			}
			& .note-modal-body {
				padding: $modal-inner-padding;
				
				& .form-group {
					padding-bottom: $spacer;
					
					& .note-form-label {
						margin-bottom: $form-label-margin-bottom;
						font-size: $font-size-base;
						font-weight: $font-weight-normal;
						color: inherit;
						display: block;
						padding: 0;
					}
					& .note-form-control {
						border: $input-border-width solid $input-border-color;
						padding: $input-padding-y $input-padding-x;
						font-family: $input-font-family;
						font-size: $input-font-size;
						font-weight: $input-font-weight;
						line-height: $input-line-height;
						background: $input-bg;
						
						&:focus {
							border-color: $input-focus-border-color;
						}
					}
				}
				& .checkbox {
					& label {
						padding: 0;
						margin: 0;
						display: flex;
						align-items: center;
						
						& input {
							@if $enable-rtl {
								margin-left: $spacer * 0.5;
							} @else {
								margin-right: $spacer * 0.5;
							}
						}
					}
				}
			}
			& .note-modal-footer {
				border: 0;
				height: auto;
				padding: $modal-inner-padding;
				border-top: 1px solid $modal-header-border-color;
				display: flex;
				justify-content: flex-end;
				
				& .note-btn {
					border: none;
					border-radius: $border-radius;
					
					&.note-btn-primary {
						background: none;
						border: 1px solid var(--bs-theme);
						color: var(--bs-theme);
			
						&:hover,
						&:focus {
							background: var(--bs-theme);
							color: $black;
						}
					}
				}
			}
		}
	}
}
body .note-modal-backdrop {
	background: $modal-backdrop-bg;
	opacity: $modal-backdrop-opacity;
	display: none !important;
}
.card {
	& > .note-editor {
		&.note-frame {
			border: none;
		}
	}
	& > form {
		& > .note-editor {
			&.note-frame {
				border: none;
			}
		}
	}
}