@keyframes appSidebarCollapse {
	@if ($enable-rtl) {
		from { margin-right: 0; opacity: 1; }
		to { margin-right: -$app-sidebar-width; opacity: 0 }
	} @else {
		from { margin-left: 0; opacity: 1; }
		to { margin-left: -$app-sidebar-width; opacity: 0 }
	}
}
@keyframes appContentCollapse {
	@if ($enable-rtl) {
		from { margin-right: $app-sidebar-width; }
		to { margin-right: 0; }
	} @else {
		from { margin-left: $app-sidebar-width; }
		to { margin-left: 0; }
	}
}
@keyframes appFooterCollapse {
	@if ($enable-rtl) {
		from { margin-right: $app-sidebar-width; }
		to { margin-right: $app-content-padding-x; }
	} @else {
		from { margin-left: $app-sidebar-width; }
		to { margin-left: $app-content-padding-x; }
	}
}


.app-sidebar-collapsed {
	@include media-breakpoint-up(md) {
		& .app-sidebar {
			opacity: 0;
			animation: appSidebarCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
			
			@if ($enable-rtl) {
				margin-right: -$app-sidebar-width;
			} @else {
				margin-left: -$app-sidebar-width;
			}
		}
		& .app-content {
			animation: appContentCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
			
			@if ($enable-rtl) {
				margin-right: 0;
			} @else {
				margin-left: 0;
			}
		}
		& .app-footer {
			animation: appFooterCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
			
			@if ($enable-rtl) {
				margin-right: $app-content-padding-x;
			} @else {
				margin-left: $app-content-padding-x;
			}
		}
	}
}