.app {
  .slider {
    &.slider-horizontal {
      height: rem(24px);
      width: 100%;
    
      & .slider-track {
        height: rem(6px);
        margin-top: rem(-3px);
      }
    }
    &.slider-vertical {
      margin: 0 auto;
      display: block;
      
      & .slider-track {
        width: rem(6px);
        
        @if $enable-rtl {
        	right: 50%;
					margin-right: rem(-3px);
				} @else {
        	left: 50%;
					margin-left: rem(-3px);
				}
      }
    }
    &.slider-disabled {
      & .slider-track {
        opacity: 0.5;
      	background: rgba($white, .15);
      }
      & .slider-handle {
        background: $gray-300;
        cursor: not-allowed;
      }
    }
    & .slider-track {
      box-shadow: none;
      background: rgba($white, .15);
      border-radius: $border-radius;
      
      & .slider-selection {
        background: var(--bs-theme);
        box-shadow: none;
      }
    }
    & .slider-handle {
      background: $white;
      box-shadow: 0 rem(1px) rem(6px) rgba($gray-900, .25);
    }
    & .tooltip {
      &.in {
        opacity: 1;
      }
      &.top {
        margin-top: rem(-30px);
      }
      &.bottom {
        margin-top: rem(25px);
      }
      &.left {
        @if $enable-rtl {
					margin-right: rem(-30px);
				} @else {
					margin-left: rem(-30px);
				}
      }
      &.right {
        @if $enable-rtl {
					margin-right: rem(5px);
				} @else {
					margin-left: rem(5px);
				}
      }
    }
  }
}